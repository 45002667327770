import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Terms from "../components/TermsConditions";

const TermsPage = () => (
  <Layout>
    <SEO title="FIND Mapping Limited" />
    <Terms />
  </Layout>
);

export default TermsPage;