import React from 'react';

const Terms = props => {

  return (
    <section className="terms">
      <h1>FIND - Terms and conditions</h1>
      <p className="privacy-normal">This Site is owned and operated by FIND Mapping Limited, The Whitehouse, Belvedere Road, London, SE1 8GA, registered in England under company number 6298825 ("FIND", "we", "us", "our").</p>
      <p className="privacy-normal">In these Terms and Conditions, the following words and phrases shall have the following meanings:</p>
      <p className="privacy-normal">"Business Hours" means 9.00 am – 5.00 pm on any Business Day.</p>
      <p className="privacy-normal">"Business Day" means Monday to Friday, inclusive, but does not include national, public, or bank holidays.</p>
      <p className="privacy-normal">"Customer", "you", "your" means the business that has registered to use the Site (and any individual registrant is deemed to act on behalf of their business).</p>
      <p className="privacy-normal">"Data" means any information contained on the Site, in the Products and the Services.</p>
      <p className="privacy-normal">"Data Suppliers" means our third party data suppliers who provide Products or Services including, but not limited to: Blom Aerofilms Limited; Bluesky International Limited; British Geological Survey; The Coal Authority; Corporation of London; Countryside Council for Wales; English Heritage; Environment Agency; The GeoInformation Group; Getmapping plc; Glenigan Limited; Greater London Authority; GroundSure Limited; HM Land Registry; Natural England; Ordnance Survey; The Royal Society for the Protection of Birds; and/or Scottish Natural Heritage.</p>
      <p className="privacy-normal">"Excluded Body" means central government departments, Crown bodies, non-departmental public bodies, local authorities, other public sector organisations (including companies in public ownership) and/or utility companies managing fixed assets as follows: gas companies; electricity companies; water companies; cable operators; oil companies; and/or fixed line telecommunication companies.</p>
      <p className="privacy-normal">"Export" means an electronic extract of the Data to include all features and all associated attributes which are available in the format in which the export is supplied.</p>
      <p className="privacy-normal">"Intellectual Property Rights" means all copyright, patent rights, trade or service marks, design right, rights in or relating to databases, rights in or relating to confidential information, and any other intellectual property rights (registered or unregistered) throughout the world.</p>
      <p className="privacy-normal">"Licence" means the licence granted by FIND to you under these Terms and Conditions in respect of the Products and the Services.</p>
      <p className="privacy-normal">"Ordnance Survey Data" means data which is provided by Ordnance Survey.</p>
      <p className="privacy-normal">"Price List" means the price of each of the Products and the Services for which FIND shall charge.</p>
      <p className="privacy-normal">"PAF Data" means the database, or any part of it, known as the 'Postcode Address File' containing all known address and Postcode information in the United Kingdom as may be amended from time to time. 'PAF' is a registered trade mark of Royal Mail Group plc.</p>
      <p className="privacy-normal">"Plot" means the representation of Data on (i) a hard copy medium; or (ii) a file capable of electronic storage which does not allow interaction with any other Data, and in particular, does not allow access to that part of the source Data which identifies the National Grid coordinates of any of the Ordnance Survey Data represented.</p>
      <p className="privacy-normal">"Products" means any product available on the Site including the Data Reports.</p>
      <p className="privacy-normal">"Services" means any services available on the Site.</p>
      <p className="privacy-normal">"Site" means the web site located at the URLs www.findmaps.co.uk and www.findmaps.com or any other additional or replacement site(s) from time to time.</p>
      <p className="privacy-normal">"Terms and Conditions" means these terms and conditions which govern your use of the Site, and the Services and the sale and purchase of Products.</p>
      <p className="privacy-normal">"View" means the representation of Data on a laptop, PC, workstation or other equipment containing a display screen on which Data may be displayed.</p>
      <p className="privacy-normal">"VPE Service" means the View, Plot and Export service provided by FIND where users are given the facility to View, Plot and Extract.</p>
      
      <p className="privacy-subheading">General use of the Site</p>
      <p className="privacy-normal">You agree and acknowledge that when you are using the Site and purchasing or receiving any Products or Services, that you are legally bound by these Terms and Conditions. Some of our Data Suppliers also apply additional specific terms and conditions which are available below and on the Site. You are also bound by these where relevant.</p>
      <p className="privacy-normal">FIND may, from time to time, amend or update these Terms and Conditions without notice to you. As such, you should check these Terms and Conditions each time you access the Site. In using the Site (and purchasing or receiving any Products or Services), you agree to any updates or amendments to these Terms and Conditions.</p>
      <p className="privacy-normal">FIND may, in it's sole discretion, suspend or terminate your use of the Site if, in FIND's reasonable opinion, it believes that you may have contravened these Terms and Conditions.</p>
      <p className="privacy-normal">This Site and the use of the Products and the Services are intended for use by businesses only and not for non-professional or non-business use.</p>
      <p className="privacy-normal">These Terms and Conditions, together with any Data Supplier specific terms of use and together with FIND's Privacy Policy contains all the terms applicable concerning your use of the Site and the purchase or receipt of Products and Services.</p>
      <p className="privacy-normal">Although access to the non-transactional parts of the Site does not require registration, you will need to register with us in order to purchase and receive Products and to access certain of the Services. Registration is currently free of charge, and must be completed online, by completing the form on the Site. By submitting a registration form, you warrant that the particulars you provide are accurate and complete. We may carry out validation checks (including contacting you using the contact details you provide) in relation to any particulars you provide for the purposes of registration and/or credit checks in relation to your business and we reserve the right, in our sole discretion, to decline any registration application. We will send you a registration confirmation email once your registration has been completed.</p>
      <p className="privacy-normal">When you register on and use this Site, and when you purchase or receive Products or Services, you do so on behalf of the business for whom you work and these Terms and Conditions constitute a contract between us and that business. That business is responsible for all your acts and omissions and reference to "Customer", "you" and "your" means you on behalf of that business.</p>
      <p className="privacy-normal">The first person from your business to register with us will be designated as your account administrator and will be entitled to add new users, provided they work for the same organisation, to your account. An explanation of the different categories of user and the access permissions associated with each category can be found by clicking through this link. You should bear in mind that each additional user who is added to your account will be entitled to order Products and Services on behalf of your business, for which charges may be payable in accordance with our Price List. By adding additional users, you warrant that they have authority to order Products and Services.</p>
      <p className="privacy-normal">You must keep your account details (including, in particular, contact details) up-to-date at all times and update them, as necessary, either by logging into the "My account" section of the Site or by contacting us through any of the methods specified on the Site. In the same way, you must delete each user on your account immediately s/he ceases to work for your business. Your account will continue to be charged for any Products or Services ordered by such user until you do so.</p>

      <p className="privacy-subheading">Relationship between FIND and Data Suppliers</p>
      <p className="privacy-normal">Unless otherwise expressly stated, FIND is not and does not represent, that it is in partnership with, that it has entered into a joint venture with, or that it is an agent of, any Data Supplier (nor does it hold itself out as doing so).</p>
      <p className="privacy-normal">FIND does not have (nor does it hold itself out to have) the authority to enter into any agreement on behalf of a Data Supplier, make any representations, undertakings or warranties on a Data Supplier's behalf nor does it seek to otherwise bind or oblige a Data Suppliers in any way. This does not affect the enforceability of any Data Supplier specific terms between you and a Data Supplier.</p>

      <p className="privacy-subheading">Scope of licence</p>
      <p className="privacy-normal">You may only use the Site, the Products or the Services within the scope of the Licence below. For the avoidance of doubt, any reference to the Licence shall include reference to any Data Supplier's specific terms and conditions. If it comes to the attention of FIND, that you have acted outside of the scope of the Licence, FIND reserves the right without exception to suspend and or terminate your use and access to the Site. In doing so, this shall not prohibit FIND from taking any other action it considers necessary and appropriate in order to protect it's Intellectual Property Rights, or the Intellectual Property Rights of its Data Suppliers.</p>
      <p className="privacy-normal">In the event that you wish to use the Site or any Products or Services for any purpose outside of the scope of the Licence and these Terms and Conditions, you must first contact FIND in writing describing in full the intended use of the Site, the Products or Services (as applicable). FIND and its Data Suppliers are not under any obligation to allow any such use.</p>
      <p className="privacy-normal">FIND grants you a limited, non-transferable, non-exclusive, revocable licence to use the Site, the Products and the Services for business use, subject to the following. Unless otherwise stated (including those set out in "Additional requirements, exceptions and variations to the Licence" at Section 6) you shall have no right to:</p>
      <p className="privacy-normal">
      1. resell access to the Site or Services, or resell the Products (including copies of the Products), or any part of the same; or<br /> 
      2. modify, merge, market, translate, publish or alter the Site, the Products or the Services; or<br />
      3. use the Site, the Products or the Services together with any product provided through the Site other than for internal business purposes only; or<br />
      4. display the Site, the Products or the Services (including copies of any part of them) to third parties; or<br />
      5. create any product containing, derived from or based on the Site, the Products or the Services, nor allow anyone else to do the same; or<br />
      6. sublicence any of the Intellectual Property Rights (or purport to own any Intellectual Property Rights) contained in the Site, the Products or the Services.
      </p>

      <p className="privacy-subheading">General IPR provisions</p>
      <p className="privacy-normal">All Intellectual Property Rights in the Site, the Products and the Services are and shall remain at all times the property of FIND and/or its Data Suppliers and/or its other third party licensors.</p>
      <p className="privacy-normal">No provision in these Terms and Conditions shall act in a way to effect the assignment or transfer of any Intellectual Property Rights in the Site the Products and/or the Services.</p>
      <p className="privacy-normal">You may not and shall not delete, remove, alter, amend, obscure or add any copyright notice, logo, trade or service mark from the Site, the Products and/or the Services, unless any other part of these Terms and Conditions expressly oblige you to do so.</p>
      <p className="privacy-normal">You may not and shall not modify, adapt, reverse engineer or enhance the Site, the Products and/or the Services.</p>
      <p className="privacy-normal">You must immediately notify FIND (i) if you suspect that use of the Site, the Products and/or the Services may infringe any third party Intellectual Property Rights or other third party rights; and, of any claims or proceedings brought against you that may affect the rights and interests of FIND or any of its Data Suppliers in respect of any Intellectual Property Rights in the Site, the Products and/or the Services. In each case, you will permit FIND in the name of the Customer to have full conduct or control of any related proceedings or negotiations.</p>

      <p className="privacy-subheading">Licence period</p>
      <p className="privacy-normal">Unless otherwise stated, you may use and retain copies of the Data and the Data Reports for a period of 1 year following the date on which the Data and/or the Data Report was purchased. At the earlier of (i) the expiry of 1 year following the date on which the Data and/or the Data Report was purchased; and (ii) termination of your right to access the Site (for whatever reason), you shall destroy all copies of the Data and/or any Data Reports within your possession or control, and the relevant Licence shall terminate immediately.</p>
 

      <p className="privacy-subheading">Additional requirements, exceptions and variations to the Licence</p>
      <p className="privacy-normal">Environment Agency: You acknowledge and agree that if your use of any part of the Environment Agency derived Data in accordance with these Terms and Conditions infringes any Intellectual Property Rights, the Environment Agency may use all reasonable endeavours to obtain the right (without charge) for you and FIND to continue to use and to distribute the infringing Data. If, however, the Environment Agency is unable to do this, it may use all reasonable endeavours to modify (or replace) the infringing Data so as to be as close to the usefulness of the original Data as reasonably possible or (if this is not possible) remove the infringing Data.</p>
      <p className="privacy-normal">GroundSure Limited: In respect of Data Reports provided by GroundSure, GroundSure shall provide a helpline service to you for certain of the GroundSure Data Reports (the "GroundSure Helpline"). The GroundSure Helpline will be manned by Institute of Environmental Management and Assessment consultants who are able to clarify matters arising in GroundSure Data Reports. You are encouraged to contact the GroundSure Helpline directly in relation to such issues should it become necessary.</p>
      <p className="privacy-normal">HM Land Registry:</p>
      <p className="privacy-normal">
      1. In relation to any HM Land Registry derived Data, you will not use or retain copies of files and information other than to deal with queries raised about a HM Land Registry service provided through the Site.<br /> 
      2. You will preserve the integrity of and prevent loss and corruption of the files and information sent by HM Land Registry to you.<br />
      3. You will not use, process or forward the product or service provided by the HM Land Registry through this Site for any purpose other than for conveyancing or conveyancing related purposes (including but not limited to Home Information Packs, mortgaging, remortgaging and the discharge of mortgages).<br />
      4. The provisions in Section 15 shall not restrict or affect a person’s entitlement to claim indemnity from HM Land Registry under section 103 and Schedule 8 of the Land Registration Act 2002<br />
      5. The provision and use of the HM Land Registry elements through this Site are subject to the provisions in the Land Registration Rules 2003 and Land Charges Rules 1974 and the conditions contained in the Notice of the Chief Land Registrar relating to the Connect Direct service provided to you through this Site.<br />
      </p>
      <p className="privacy-normal">Ordnance Survey: You acknowledge and agree that the Crown (or, where applicable, Ordnance Survey's suppliers) owns the Intellectual Property Rights in the Ordnance Survey Data. All Ordnance Survey Data licenced to FIND shall remain the property of the Crown (or, where applicable, Ordnance Survey's suppliers) and FIND's use or possession of any Ordnance Survey Data does not give FIND any ownership of or other interest in any of the Ordnance Survey Data. Title to any copies that you or FIND make of Ordnance Survey Data shall pass to Ordnance Survey on their creation.</p>
      <p className="privacy-normal">You agree and acknowledge that in respect of any Data or Data Reports or information produced by or provided by or on behalf of Ordnance Survey that:</p>
      <p className="privacy-normal">
      1. the Ordnance Survey Data Reports shall be available to access for a period of one month only following the date upon which it was first made available to you; and<br />
      2. you may save any text or shapes that are drawn on the report using FIND's Product tool only and that FIND may store such shapes or text and the Data Report for up to six months to allow you to access the Data Report and any text or shapes that are drawn on the Data Report during this period; and<br />
      </p>
      <p className="privacy-normal">You agree and acknowledge in respect of the Ordnance Survey derived VPE Service on this Site, that:</p>
      <p className="privacy-normal">
      1. your right to use each Plot file and to hold Export files is limited to a maximum term of twelve months from the date of creation of that Plot file or date of the Export after which you are obliged to destroy the Plot file or Export file (as applicable); and.<br />
      2. you are only licenced to use each Plot file internally for your own business; and<br />
      3. you may only print one copy from a Plot file; and<br />
      4. you are not permitted to photocopy any hard copy medium Plots under this Licence; and<br />
      </p>
      <p className="privacy-normal">In respect of all Ordnance Survey Data, you are licenced to use such data for Internal Business Use only. Internal Business Use is defined as the use of Ordnance Survey Data in the ordinary day-to-day activities involved in the internal administration and running of the your business or organisation.</p>
      <p className="privacy-normal">Such use of Ordnance Survey Data is only permitted in the following circumstances:</p>
      <p className="privacy-normal">
      1. solely and explicitly for the administration and operation of your business or organisation (which excludes its supply to any third party unless otherwise expressly permitted);<br />
      2. in reports and submissions to third parties (where such activities relate to the internal administration and running of your business or organisation and the you shall advise such third parties that such Ordnance Survey Data shall not be used for any other purpose), provided such reports and submissions do not carry paid for advertising in respect of third parties. Such use shall include electronic transmission of a graphic image that is a raster data file produced solely for the purposes of allowing such third party to view and print one copy;<br />
      3. by your contractors and agents when undertaking any activity for you which you are permitted to undertake but solely and explicitly to provide the Ordnance Survey Data for the purpose of enabling them to provide goods or services to you or to tender for the provision of such goods or services. You must require such contractor or agent to sign a contractor licence with you in the form specified by Ordnance Survey (the "Contractor Licence"). You must enforce the provision in such Contractor Licence which requires the contractor or agent to erase all copies of Ordnance Survey Data on or before termination of such Contractor Licence. This must include those held in paper based or any electronic format, provided that you may permit such contractor or agent to retain one paper based archive copy of Ordnance Survey Data which is relevant and necessary to document the goods or services delivered to you;<br />
      4. where Ordnance Survey Data are available on an internal network or on a remotely accessible server operated by an electronic hosting service, you must either take steps yourself or enter into a written agreement in relation to the facilities provided by the electronic hosting service to provide for appropriate industry standard safeguards restricting third party access to any of your data which includes or incorporates any Ordnance Survey Data;<br />
      5. within any professional services provided by you to your clients, provided that:<br />
        a. the provision of Ordnance Survey Data is not a service in itself and does not form a significant part of any service offered by you;<br />
        b. Ordnance Survey Data may be provided only in paper form or by electronic transmission of a graphic image that is a raster file produced solely for the purposes of allowing the recipient to view and print one copy;<br />
        c. only such amounts of Ordnance Survey Data may be used as are necessary to meet the specific need for which they are used; and<br />
        d. the use to which such clients shall put the Ordnance Survey Data shall be personal (in the case of a consumer client) or for the administration and operation of its business (in the case of a business client);<br />
        provided that the rights referred to in this Section 6.9(e) shall not apply where you are an Excluded Body.
      </p>
      <p className="privacy-normal">For the avoidance of doubt the permission of Ordnance Survey for such supply of Ordnance Survey Data to third parties as specified in Section 6.9(c) above is given on the basis that you remain responsible and primarily liable to Ordnance Survey for the acts and omissions of such contractors and agents.</p>
      <p className="privacy-normal">Such use does not extend to use of the Ordnance Survey Data:</p>
      <p className="privacy-normal">We use your information to: </p>
      <p className="privacy-normal">We use your information to: </p>
      <p className="privacy-normal">We use your information to: </p>
      <p className="privacy-normal">We use your information to: </p>
      <p className="privacy-normal">We use your information to: </p>
      <p className="privacy-normal">We use your information to: </p>
      <p className="privacy-normal">We use your information to: </p>
      <p className="privacy-normal">We use your information to: </p>

      <p className="privacy-subheading">Delivery and specification of Data Reports</p>
      <p className="privacy-normal">FIND will use reasonable efforts to submit Data Report orders in accordance with your reasonable requests.</p>
      <p className="privacy-normal">Data Suppliers will use reasonable efforts to acknowledge receipt of each order confirmation and deliver or procure the delivery of Data Reports requested on each order to you as specified and on the date specified in the order confirmation.</p>
      <p className="privacy-normal">FIND will use reasonable efforts to ensure that Data Suppliers supply the Data Reports in accordance with the specifications set out on the Site, as amended from time to time.</p>
      <p className="privacy-normal">You acknowledge that the Data Reports are provided on an "as is" basis, and that the Data Report has not been prepared to meet your individual requirements and therefore you should not rely on it as such.</p>
      <p className="privacy-normal">In respect of the ordering and provision of Data Reports, FIND shall use reasonable efforts to order any Data Reports requested by you as soon as possible. You acknowledge that FIND's ability to order Data Reports is subject to the operating hours of the Data Suppliers. If Data Reports are ordered outside of Business Hours, applications will be lodged with the relevant Data Supplier on the next Business Day. With respect to the delivery of any Data Report, time shall not be of the essence.</p>

      <p className="privacy-subheading">Pricing</p>
      <p className="privacy-normal">Where FIND invoices you for a number of Products or Services at the same time, any failure by FIND or the Data Supplier to provide any one of those elements shall not entitle you to withhold payment for the remainder which it has received.</p>
      <p className="privacy-normal">All invoices are payable within 30 days.</p>
      <p className="privacy-normal">If any invoice remains unpaid for 30 days from the date of the invoice, FIND are entitled to charge interest on all sums unpaid at a rate of 4% over the Barclays Bank plc base rate.</p>
      <p className="privacy-normal">FIND may, at its absolute discretion, change the pricing structure at any time without notice.</p>
      <p className="privacy-normal">You agree not to circumvent the charging procedure operated through the Site.</p>

      <p className="privacy-subheading">Termination and consequences of termination</p>
      <p className="privacy-normal">Subject to any other provision in these Terms and Conditions, you may continue to use the Site providing your account remains active. If your account is inactive for a period of 12 consecutive months we reserve the right to deactivate your account.</p>
      <p className="privacy-normal">If FIND terminates your use of this Site where you are in breach of your obligations under these Terms and Conditions, you will not be allowed to access the Site.</p>
      <p className="privacy-normal">Expiry or termination of these Terms and Conditions however caused, and for whatever reason, shall be without prejudice to any rights or liabilities accrued at the date of termination or expiry and in particular without limitation to any foregoing right to recover damages from each other.</p>

      <p className="privacy-subheading">Passwords</p>
      <p className="privacy-normal">When you register at the Site you will be issued with an individual password which may be used to access the Site. You shall use the password only for such access and not for any other purpose.</p>
      <p className="privacy-normal">You must keep your password confidential at all times, and must not disclose the password or permit anyone else to use your password. Any breach of any of this term by anyone to whom you disclose the password will be treated as if the breach had been committed by you, and will not relieve you of your obligations under these Terms and Conditions.</p>
      <p className="privacy-normal">We reserve the right to disable your password, at any time, if in our opinion you have breached any of these Terms and Conditions, including if any details you provide for the purposes of registering to use the Site prove to be false. In the event of any failure or error in the operation of a password, you shall cease using the password and exit the Site immediately, and notify FIND of such failure or error. You must cease to use and delete the password from any of your records upon expiry or termination of your subscription for whatever reason.</p>
      <p className="privacy-normal">FIND reserves the right to change your password and user name at any time in its sole discretion and you agree to notify FIND promptly of any changes to your registration details</p>

      <p className="privacy-subheading">Information on the Site</p>
      <p className="privacy-normal">FIND and it's Data Suppliers reserve the right (without any obligation) to update the information, the interface and/or the services provided on or through this Site at any time with or without notice. FIND will work with the Data Suppliers to update the information on the Site so that it is accurate and up to date within reason. However, you acknowledge that the type of Data displayed on the Site is subject to constant change and that as such it quickly become out of date and neither FIND nor the Data Suppliers warrant the accuracy, completeness or currency of the Data provided through the Site.</p>
      <p className="privacy-normal">Where possible, and within reason (but subject at all times to FIND's sole discretion) FIND shall work with its Data Suppliers to inform and give notice to you, where reasonably possible, of any updates or inaccuracies in the Products, the Services or on the Site.</p>
      <p className="privacy-normal">You acknowledge and agree that you shall not rely on the currency and accuracy of the Products and the Services and that you shall make your own independent enquiries or procure the services of professional advisers where you wishes to rely on such information.</p>
      <p className="privacy-normal">It is your responsibility to ensure that the Products and Services are fit for the purpose that you require.</p>
      <p className="privacy-normal">You agree that you are responsible for inputting data which is relevant to your search and requirements, and that neither FIND nor the Data Suppliers shall have any liability in the event that any information is submitted incorrectly or incompletely. You agree that you will bear any costs already incurred or which will be incurred for any Products or Services where you have submitted incorrect or incomplete data.</p>

      <p className="privacy-subheading">Website availability and maintenance</p>
      <p className="privacy-normal">FIND is under no obligation to provide the Services on the Site outside of Business Hours (and in any event the Site will be subject to downtime from time to time including for maintenance as specified below).</p>
      <p className="privacy-normal">FIND may suspend the operation of the Site for repair or maintenance work or in order to update or upgrade the content, Data or functionality of the Site from time to time. Access to or use of the Site, the Product, the Services or pages linked to it will not be uninterrupted or error free.</p>

      <p className="privacy-subheading">Liability</p>
      <p className="privacy-normal">We are not allowed to exclude or limit liability for death or personal injury caused by our negligence, or for fraud, or that we will use reasonable care and skill in providing the Services, or for any other liability which may not by applicable law be excluded or limited and we do not do so.</p>
      <p className="privacy-normal">Subject to Section 14.1, FIND shall not be liable to you or any third party (whether for breach of contract, negligence or for any other reason) for any: loss of profits; loss of sales; loss of revenue; loss of any software or data; loss of use of hardware; loss of use of software or data; loss of business resources; wasted management or staff time; any inaccuracy or incompleteness in, or delays, errors or omissions in the delivery of the Service, Product; any decision made or action taken by you in reliance upon the Service, Product or Data Report; or any indirect loss, consequential loss or special loss.</p>
      <p className="privacy-normal">Subject to the preceding paragraphs in this Section 14, if FIND is in breach of any of its obligations under these Terms and Conditions, FIND's liability shall be limited to the lesser of (i) £5,000; and (ii) 125% the fees paid by you (in respect of the Site, the Product and the Services) in the 12 months preceding the date upon which the act gave rise to the liability occurring.</p>
      <p className="privacy-normal">If you discover that there is a defect within the content of the Product or the Service, you shall contact FIND in writing notifying it of the defect. Subject to Section 14.1, FIND shall have no liability to you if you do not do so within (i) 7 days of discovering the defect; or (ii) 90 days from receiving the Product, Data, Data Report or Service.</p>
      <p className="privacy-normal">Subject to Section 14.1, FIND shall not be responsible for errors or corruption in the Products and the Services resulting from inaccuracy or omission in information and/or data, inaccurate processing of information and data by third parties including the Data Suppliers, or for computer malfunction or corruption of data whilst in the course of providing the Products and the Services.</p>
      <p className="privacy-normal">FIND warrants that the Services will be provided with reasonable care and skill. Subject to the foregoing, all representations, guarantees, warranties, undertakings, conditions and any other term (whether express or implied by statute or common law) as to fitness of the Site, the Products and the Services for a particular purpose or satisfactory quality are expressly excluded to the fullest extent permitted under English law.</p>

      <p className="privacy-subheading">Indemnities</p>
      <p className="privacy-normal">You shall indemnify FIND against all claims, losses, costs, liabilities, expenses and damages (whether direct or indirect) for the following:</p>
      <p className="privacy-normal">any losses that FIND discover or may incur following its undertaking of a compliance audit of the Customer (which shall be undertaken in accordance with the auditing provisions set out in these Terms and Conditions), including in relation to any losses which may arise as a result of non-compliance with these Terms and Conditions;</p>
      <p className="privacy-normal">any use of the Site, the Products or the Services (i) outside the scope of the Licence; or (ii) in contravention of these Terms and Conditions.</p>

      <p className="privacy-subheading">Compliance with applicable law</p>
      <p className="privacy-normal">You will comply with all requirements under applicable law in relation to the Site, the Products and the Services.</p>

      <p className="privacy-subheading">Auditing rights</p>
      <p className="privacy-normal">You shall be obliged to maintain and keep copies of records relating to the purchase and use of the Products and the Services through this Site.</p>
      <p className="privacy-normal">You agree that FIND, upon the provision of reasonable written notice, shall be entitled to inspect your records and you grant access to all relevant personnel for the purposes of conducting a compliance audit in order to ensure that the Products and the Services it has provided is being used in accordance with these Terms and Conditions including the Data Supplier specific terms and conditions. You agree that, where required to do so, FIND shall be entitled to provide your details to a Data Supplier for the same purposes and generally for the purposes of enforcing the Data Supplier's rights.</p>
      
      <p className="privacy-subheading">Disputes and governing law</p>
      <p className="privacy-normal">In respect of any disputes concerning anything which is covered by these Terms and Conditions you agree that disputes shall first be attempted to be resolved amicably by your senior management and FIND's senior management.</p>
      <p className="privacy-normal">These Terms and Conditions shall be governed by English law, and the English courts shall have exclusive jurisdiction.</p>

      <p className="privacy-subheading">General</p>
      <p className="privacy-normal">If any provision of these Terms and Conditions are deemed void, unlawful or otherwise unenforceable for any reason, that provision shall be severed from this agreement and the remaining provisions of this agreement shall remain in force.</p>
      <p className="privacy-normal">In the event that any provisions within these Terms and Conditions conflict with the Data Supplier specific terms and conditions, these Terms and Conditions shall prevail. When one set of terms is more restrictive than another in respect of the same subject matter, the more restrictive set will apply.</p>
      <p className="privacy-normal">You shall not, unless expressly otherwise permitted, contact the Data Suppliers directly.</p>
      <p className="privacy-normal">FIND shall not be liable for failure to perform its obligations under these Terms and Conditions if caused by or resulting from force majeure which shall include, but not be limited to events which are outside of its reasonable control.</p>
      <p className="privacy-normal">Unless the parties expressly agree otherwise in writing, if a party either (i) fails to exercise or delays exercising or only exercises partially any right or remedy provided under these Terms and Conditions or by law or (ii) agrees not to exercise or to delay exercising any right or remedy provided under these Terms and Conditions or by law then that party shall not be deemed to have waived and shall not be precluded or restricted from further exercising that or any other right or remedy. Unless the parties expressly agree otherwise in writing, no right, power or remedy under these Terms and Conditions or otherwise available to a party is exclusive of any other right, power or remedy under this agreement or otherwise available to that party.</p>
      <p className="privacy-normal">FIND may assign or transfer any part or the whole of its rights and obligations under these Terms and Conditions at any time without the obligation to provide written notice. You shall not be entitled to assign or transfer any of your rights or obligations under these Terms and Conditions, without first having obtained the written consent of FIND.</p>
      <p className="privacy-normal">A person who is not a party to these Terms and Conditions shall have no right under the Contract (Rights of Third Parties) Act 1999 to enforce any of these Terms and Conditions unless expressly stated in these Terms and Conditions. Any Data Supplier may enforce these Terms and Conditions, or any Data Supplier specific terms and conditions against you in accordance with Contract (Rights of Third Parties) Act 1999.</p>
      <p className="privacy-normal">Any notices to FIND must be made in writing to the following address: FIND, The Whitehouse, Belvedere Road, London, SE1 8GA. Any notices to you will be made either (i) in writing to you; or (ii) posted on this Site.</p>
      <p className="privacy-normal">If you have questions regarding these Terms and Conditions, please email: support@findmaps.co.uk.</p>
      <p className="privacy-normal">These Terms and Conditions were last updated on 1st May 2018.</p>
 
 
    </section >
  )
};

export default Terms;